@import url('https://use.typekit.net/uqy0veg.css');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Oswald:400,500,600,700');

:root {
    --bodyTxt: #ffffff;
    --livePrizePoolLabelBg: #ff0000;
    --livePrizePoolLabelTxt: #ffffff;
    --primaryBtnBg: #ff0000;
    --primaryBtnTxt: #ffffff;
    --secondaryBtnBorder: 1px solid #ffffff;
    --secondaryBtnTxt: #ffffff;
    --splitPrizeBg: #ffffff;
    --splitPrizeTxt: #0000ff;
    --ticketsSoldTxt: #ffcc00;
    --background: url("");
    --tableRowTxt:var(--splitPrizeBg);
}

body {
    margin: 0px;
    padding: 0px;
    background-color: #333333;
    background-size: cover;
    font-family: 'Oswald', sans-serif;    
    background-image:var(--background);
    background-repeat: no-repeat;
}

/*body::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 100vh 90vw;
    border-color: transparent transparent rgba(0,0,0,.2) transparent;
    display: block;
    z-index: -1;
    position: fixed;
    bottom: 0;
    right:0;
}*/

.progress {
    border-radius: 0;
    height:3px;
    width: 100%;
    position: fixed;
    top:0;
}

.progress-bar {
    transition: width 1s linear;
    -webkit-transition: width 1s linear;
    background-color: var(--primaryBtnBg);
}

.buyBtn {
    padding:10px;
    background-color: var(--primaryBtnBg);
    color: white;
    border-radius: 0;
    font-size: 24px;
    border:none;
    margin-right: 3px;
    text-transform: uppercase;
}

.buyBtn:active {
    background-color: var(--splitPrizeBg);
    color: var(--splitPrizeTxt);
}


.buyBtn.btn-primary:not(:disabled):not(.disabled):active {
    background-color: var(--splitPrizeBg);
    color: var(--splitPrizeTxt);
    box-shadow: none;

}

.buyBtn.btn-primary:not(:disabled):not(.disabled).active {
    background-color: var(--splitPrizeBg);
    color: var(--splitPrizeTxt);
    outline: none !important;
    box-shadow: none;
}

.buyBtn:active, .buyBtn.btn-primary:hover,.buyBtn.btn-primary:focus {
    background-color: var(--splitPrizeBg);
    color: var(--splitPrizeTxt);
    outline: none !important;
}


/* styles.css */
/* colors */
.backgroundImage {
    background-size: cover;
    font-family: 'Oswald', sans-serif;    
}

.contentContainer {
    position: relative;
}

h4 {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
}

h4.txt-red {
    line-height: 1.5em;
}

h4.txt-gld {
    color: var(--ticketsSoldTxt);
    font-weight: 500;
}

p {
    font-size: 24px;
    color: var(--bodyTxt);
    margin: 2rem 0 2rem 0;
}

a {
    text-decoration: none;
}

a.small {
    cursor: pointer;
    letter-spacing: .5px;
}

a.small:hover {
    text-decoration: underline !important;
}

.hero {
    position: fixed;
    top: 20px;
    right: 0;
    height: 50vh;
    z-index: 0;
}


.hero img {
    height: 120vh;
}

@media (max-width: 768px) {
    .hero {
        opacity: .2;
    }
}

.content {
    max-width: 375px;
    text-align: center;
    color:var(--bodyTxt);
    padding: 60px 0 40px 0;
}

#event_no span {
    color: var(--bodyTxt);
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
    opacity: .4;
}

.box {
    background:var(--splitPrizeBg);
    padding: 12px;
    margin-bottom: 2px;
}

.box span {
    font-size: 36px;
    color: var(--splitPrizeTxt);
    font-weight: bold;
}

.box h4 {
    text-transform: uppercase;
    color: var(--splitPrizeTxt);
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
}

.box.transparent {
    opacity: .6;
}

/********** BUTTONS ************/
button.btn {
    border-radius: 0;
    outline: none;
    border: none;
    text-transform: uppercase;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    display: block;
    margin: 0 auto;
    letter-spacing: 1px;
}

button.btn.btn-primary {
    background: var(--primaryBtnBg);
    color:var(--primaryBtnTxt);
    font-size: 30px;
    padding: 20px 8px;
    box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.5);
}

button.btn.btn-primary:hover {
    filter: brightness(0.9);
}

button.btn.btn-secondary {
    width: 250px;
    font-size: 24px;
    font-weight: bold;
    border:var(--secondaryBtnBorder);
    background: none;
    padding: 8px;
}

button.btn.btn-secondary:hover {
    background: rgba(255, 255, 255, 0.1);
}

button.btn.btn-tertiary {
    width: 250px;
    font-size: 18px;
    font-weight: bold;
    border:1px solid #000000;
    color: #000000;
    background: none;
    padding: 8px;
    box-shadow: none;
}

button.btn.btn-tertiary:hover {
    background: rgba(255, 255, 255, 0.1);
}


/********** TABLE ************/
table.table {
    color: var(--tableRowTxt);
    width: 75%;
    max-width: 1200px;
    margin: 30px auto;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
}

table.table td {
    border-color: rgba(255, 255, 255, 0.5);
    padding: 22px 12px;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 1px;
}

table.table td:first-child {
    text-align: left;
}

table.table .btn-secondary {
    font-size: 14px;
    opacity: .7;
    padding: 14px 8px;
    text-transform: initial;
    margin-right: 0;
    width: 200px;
}

table.table .btn-secondary:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.1);
}

/********** SHAPES ************/
#chevron {
    position: relative;
    text-align: center;
    padding: 12px;
    margin: 6px auto;
    height: 50px;
    width: 200px;
    color: var(--splitPrizeTxt);
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    z-index: 2;
    color: var(--splitPrizeTxt);
    font-size: 18px;
}

#chevron::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background:var(--splitPrizeBg);
    transform: skew(0deg, 6deg);
    z-index: -1;
}

#chevron::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background:var(--splitPrizeBg);
    transform: skew(0deg, -6deg);
    z-index: -1;
}

#prizepool {
    width: 95%;
    position: relative;
    padding: 5px;
    margin: 0 auto;
    top: -20px;
    z-index: 1;
}


#prizepool::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background:var(--livePrizePoolLabelBg);
    transform: skew(0deg, 6deg);
    z-index: -1;
}

#prizepool::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background:var(--livePrizePoolLabelBg);
    transform: skew(0deg, -6deg);
    z-index: -1;
}

#prizepool span {
    font-size: 60px;
    font-weight: bold;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
    z-index: 4;
}


.no-padding {
    padding:0;
}

.no-padding-top {
    padding-top:0;
}

.no-padding-bottom {
    padding-bottom:0;
}

.v-margin-10 {
    margin-top:10px;
    margin-bottom:10px;
}

.v-margin-20 {
    margin-top:20px;
    margin-bottom:20px;
}

.buyBtnGroup {
    padding-top:20px;
    padding-bottom: 20px;
}

.formGroup {
    padding-top:30px;
}

.errorMsg {
    white-space: pre-wrap;
}

h4.errorMsg {
    font-size: 18px;
}

/********** PROMOS ************/
.mrec {
    width: 600px;
    height:500px;
}

.square {
    width: 600px;
    height:600px;
}

.rect {
    width: 922px;
    height:482px;
    
}

.rect .hero {
    position: absolute;
    left:622px; /* rect.width (922) - hero.width (300) */
    top: 20px;
    width:300px;
    height:462px;
    z-index: 0;
    overflow: hidden;
}


.rect .hero img {
    width:300px;
    height:auto;
}

.rect h4 {
    font-size: 36px;
}

.rect #chevron {
    width:350px;
    height:100px;
    padding-top:20px;
    font-size: 40px;
}

.rect #prizepool span {
    font-size: 90px;
    font-weight: bold;
}

.cube {
    width: 1920px;
    height:1080px;
}

.scoreboard {
    width: 1920px;
    height:456px;
}

.cube .hero {
    position: absolute;
    left:1420px;
    top: 20px;
    width:500px;
    height:1080px;
    z-index: 0;
    overflow: hidden;
}


.cube .hero img {
    width:500px;
    height:auto;
}

.cube #prizepool span {
    font-size: 140px;
}

.cube #chevron {
    width:400px;
    height:100px;
    padding-top:20px;
    font-size: 40px;
}

.cube #event_no span {
    font-size: 36px;
}

.cube h4 {
    font-size: 36px;
    text-transform: uppercase;
}


.click {
    width: 1280px;
    height:500px;
}

.click .content {
    max-width: 400px;
    padding:0;
}

.click .content img{
    max-width: 120px;
}

.click .hero {
    position: absolute;
    left:934px;
    top: 20px;
    width:330px;
    height:712px;
    z-index: 0;
    overflow: hidden;
}


.click .hero img {
    width:330px;
    height:auto;
}

.click #prizepool span {
    font-size: 80px;
}

.click #chevron {
    width:calc(264*0.5);
    height:calc(66*0.5);
    padding-top:20px;
    font-size: 18px;
}

.click #event_no span {
    font-size: 18px;
}

.click h4 {
    font-size: 24px;
    text-transform: uppercase;
}

.click h5 {
    font-size: 18px;
    text-transform: uppercase;
    opacity: 0.4;
}

.click.backgroundImage {
    background-size: cover;
    background-image:var(--background);
}

.logo {
    max-width: 100%;
}